const data = {
  // pointStatus: ['全部', '在建', '预警', '喷洒'],
  pointStatus: ['消冰站点', '预警站点'],
  pointState:['在建','完工'],
  early:['蓝色预警', '黄色预警','橙色预警', '红色预警','紫色预警'],
  report:['喷洒设备故障','气象设备故障'],
  road:['干燥','潮湿','水','冰','雪','黑冰'],
  malfunction:['电动球阀故障','回流阀故障','压力计故障','液位计故障','流量计故障','环境温湿度,气压通讯故障','风速通讯故障','风向通讯故障','水冰雪厚度通讯故障','翻斗雨量桶通讯故障','发电机通讯故障'],
  articleStatus: ['全部', '草稿箱', '待审核', '已审核', '已拒审'],
  statuses: ['已创建', '待审核', '未开始', '进行中', '已完成', '', '', '', '拒审核', '已作废', '已删除'],
  taskMaterialStatus: ['待提交', '待审核', '已领料'],
  QX: {
    "QXWD": {
      label: "环境温度",
      fixed: 2,
      unit: '°C'
    },
    "QXSD": {
      label: "环境湿度",
      fixed: 2,
      unit: '%RH'
    },
    "QXQY": {
      label: "气压",
      fixed: 2,
      unit: 'Kpa'
    },
    "QXZS": {
      label: "噪声",
      fixed: 2,
      unit: 'db'
    },
    "QXLD": {
      label: "露点",
      fixed: 2,
      unit: '°C'
    },
    "QXHB": {
      label: "海拔",
      fixed: 2,
      unit: 'm'
    },
    "JYL": {
      label: "雨量",
      fixed: 2,
      unit: 'mm'
    },
    "FS": {
      label: "风速",
      fixed: 2,
      unit: 'm/s'
    },
    // 风向 另外加的
    "FX":{
      label: "风向",
      fixed: 2,
      unit: '风'
    }
  },
  LM: {
    "LMSD": {
      label: "路面温度",
      fixed: 2,
      unit: '°C'
    },
    "SMHD": {
      label: "水膜厚度",
      fixed: 2,
      unit: 'mm'
    },
    "FBHD": {
      label: "覆冰厚度",
      fixed: 2,
      unit: 'mm'
    },
    "FXHD": {
      label: "覆雪厚度",
      fixed: 2,
      unit: 'mm'
    },
    "SHXS": {
      label: "湿滑系数",
      fixed: 2,
      unit: ''
    },
    "LMZT": {
      label: "路面状态",
      fixed: 2,
      unit: ''
    }
  },
  DQ: {
    "DYA": {
      label: "A相电压",
      fixed: 2,
      unit: 'V'
    },
    "DLA": {
      label: "A相电流",
      fixed: 2,
      unit: 'A'
    },
    "DYB": {
      label: "B相电压",
      fixed: 2,
      unit: 'V'
    },
    "DLB": {
      label: "B相电流",
      fixed: 2,
      unit: 'A'
    },
    "DYC": {
      label: "C相电压",
      fixed: 2,
      unit: 'V'
    },
    "DLC": {
      label: "C相电流",
      fixed: 2,
      unit: 'A'
    },
    "DN": {
      label: "电能",
      fixed: 2,
      unit: 'Kwh'
    },
    "SYDL": {
      label: "剩余电流",
      fixed: 2,
      unit: 'mA'
    }
  },
  PS: {
    "YW": {
      label: "液位",
      fixed: 2,
      unit: 'm'
    },
    "GD1YL": {
      label: "压力计",
      fixed: '',
      unit: 'kpa',
    },
    "SXK": {
      label: "水箱宽",
      fixed: '',
      unit: 'm',
    },
    "SXC": {
      label: "水箱长",
      fixed: '',
      unit: 'm',
    },
    "ZB": {
      label: "主泵状态",
      fixed: ''
    },
    "GD1LY": {
      label: "流量计1",
      fixed: 2,
      unit: 'm³/h'
    },
    "FB1": {
      label: "副泵1状态",
      fixed: ''
    },
    "GD2LY": {
      label: "流量计2",
      fixed: '',
      unit: 'm³/h'
    },
    "FB2": {
      label: "副泵2状态",
      fixed: '',
      unit: ''
    },
    "GD3LY": {
      label: "流量计3",
      fixed: '',
      unit: 'm³/h'
    },
  },
  ZD:{
    "PLXLS": {
      label: "喷洒线路数",
      fixed: 2,
      unit: '条'
    },
    "PLTS": {
      label: "喷洒头数",
      fixed: 2,
      unit: '个'
    },
    "RXJXHL": {
      label: "融雪剂消耗量",
      fixed: 2,
      unit: '吨'
    },
    "RXJCCL": {
      label: "融雪剂存储量",
      fixed: 2,
      unit: '吨'
    },
    "PLCS": {
      label: "喷洒次数",
      fixed: 2,
      unit: '次'
    },
    "SMCS": {
      label: "水膜次数",
      fixed: 2,
      unit: '次'
    },
    "FXCS": {
      label: "覆冰次数",
      fixed: 2,
      unit: '次'
    },
    "FBCS": {
      label: "覆雪次数",
      fixed: 2,
      unit: '次'
    },
  }
}
export default {
  data
}