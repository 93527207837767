// 引入cookie-js
import Cookie from 'js-cookie'

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: () => import('@/views/index.vue')
  // component: resolve => require(['@/views/screen/index'], resolve)

}, {
  path: '/screen',
  meta: {
    title: '大屏',
    requireAuth: true
  },
  component: resolve => require(['@/views/screen/index'], resolve)
  // component: resolve => require(['@/views/screen/weather'], resolve)
  // component: resolve => require(['@/views/screen/re'], resolve)
  // component: resolve => require(['@/views/screen/echarts'], resolve)
  // component: resolve => require(['@/views/manage/point/pointLine111'], resolve)
},
{
  path: '/manage',
  component: () => import('@/views/manage/index.vue'),

  children: [{
    path: '/manage/',
    meta: {
      title: '管理后台',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/home'], resolve)
  },
  {
    path: '/manage/admin/lists',
    meta: {
      title: '管理人员',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/admin/lists'], resolve)
  },
  {
    path: '/manage/admin/group',
    meta: {
      title: '管理角色',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/admin/group'], resolve)
  },
  {
    path: '/manage/admin/menu',
    meta: {
      title: '管理权限',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/admin/menu'], resolve)
  }, {
    path: '/manage/user/lists',
    meta: {
      title: '用户管理',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/user/lists'], resolve)
  }, {
    path: '/manage/organization/lists',
    meta: {
      title: '项目组织',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/organization/lists'], resolve)
  }, {
    path: '/manage/point/lists',
    meta: {
      title: '项目管理',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/point/lists'], resolve)
  },
  {
    path: '/manage/point/add',
    meta: {
      title: '添加',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/point/add'], resolve)
  },
  {
    path: '/manage/point/pointLine',
    meta: {
      title: '喷洒管理',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/point/pointLine'], resolve)
  },
  // {
  //   path: '/manage/point/camera',
  //   meta: {
  //     title: '监控管理',
  //     requireAuth: true
  //   },
  //   component: resolve => require(['@/views/manage/point/camera'], resolve)
  // },
  {
    path: '/manage/article/lists',
    meta: {
      title: '咨讯管理',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/article/lists'], resolve)
  },
  {
    path: '/manage/article/class',
    meta: {
      title: '咨讯分类',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/article/class'], resolve)
  },
  {
    path: '/manage/article/add/:id?',
    meta: {
      title: '咨讯分类',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/article/add'], resolve)
  },
  {
    path: '/manage/product/lists',
    meta: {
      title: '产品管理',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/product/lists'], resolve)
  },
  {
    path: '/manage/product/class',
    meta: {
      title: '产品分类',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/product/class'], resolve)
  },
  {
    path: '/manage/equipment/lists',
    meta: {
      title: '设备管理',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/equipment/lists'], resolve)
  }, {
    path: '/manage/equipment/logo',
    meta: {
      title: '设备日志',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/equipment/log'], resolve)
  },
  {
    path: '/manage/log/early',
    meta: {
      title: '预警日志',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/log/early'], resolve)
  },
  {
    path: '/manage/log/report',
    meta: {
      title: '告警日志',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/log/report'], resolve)
  },
  {
    path: '/manage/log/spray',
    meta: {
      title: '喷洒日志',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/log/spray'], resolve)
  },
  {
    path: '/manage/log/weather',
    meta: {
      title: '气象日志',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/log/weather'], resolve)
  },
  {
    path: '/manage/equipment/class',
    meta: {
      title: '设备类别',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/equipment/class'], resolve)
  }, {
    path: '/manage/setting/region',
    meta: {
      title: '设置',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/setting/region'], resolve)
  },
  {
    path: '/manage/setting/global',
    meta: {
      title: '全局设置',
      requireAuth: true,
    },
    component: resolve => require(['@/views/manage/setting/global'], resolve)
  },
  {
    path: '/manage/setting/help',
    meta: {
      title: '设备帮助',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/setting/help'], resolve)
  },
  {
    path: '/manage/setting/salt',
    meta: {
      title: '融雪剂介绍',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/setting/salt'], resolve)
  },
  ]
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 导航守卫
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth == true) { // 判断该路由是否需要登录权限
    let admin = Cookie.get('admin')
    if (admin === undefined || admin === 'e30=' || !admin) { // cookie是否存在
      next({
        path: '/' // 跳转到登录界面
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
